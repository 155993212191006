import { Injectable, Injector } from "@angular/core";
import { HttpService } from "../http/http.service";
import { Observable } from "rxjs";
import { InstantUcApiModels } from "../interface/Api/i.api-response";

@Injectable({ providedIn: "root" })
export class AuthServices {
  apiController = "api/Account";
  adminLoginController = "api/AdminLogin";
  httpSerive: HttpService;

  constructor(injector: Injector) {
    this.httpSerive = injector.get(HttpService);
  }

  changeDefaultDepartment(
    body
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/ChangeDefaultDepartment`;
    return this.httpSerive.post(url, body);
  }

  findUserIdentity(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.adminLoginController}/Login`;
    return this.httpSerive.LoginPost(url, body);
  }

  Login(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/Login`;
    return this.httpSerive.post(url, body);
  }

  LogOut(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/LogOut`;
    return this.httpSerive.post(url, body);
  }

  ForgotPasswordHq(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.adminLoginController}/ForgotPasswordHQ`;
    return this.httpSerive.LoginPost(url, body);
  }
  ForgotPassword(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/ForgotPassword`;
    return this.httpSerive.post(url, body);
  }

  ResetPassword(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.adminLoginController}/ResetPasswordHQ`;
    return this.httpSerive.LoginPost(url, body);
  }

  GetUserList(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/GetUserList`;
    return this.httpSerive.post(url, body);
  }

  AddUser(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/AddUser`;
    return this.httpSerive.post(url, body);
  }

  GetRoleList(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/GetRoleList`;
    return this.httpSerive.post(url, body);
  }
  GetDepartmentListByPractice(
    body
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/GetDepartmentListByPractice`;
    return this.httpSerive.post(url, body);
  }

  GetMenuPermissionList(
    body
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/GetMenuPermissionList`;
    return this.httpSerive.post(url, body);
  }

  AddRolePermissions(
    body
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/AddRolePermissions`;
    return this.httpSerive.post(url, body);
  }
  DeleteUser(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/DeleteUser`;
    return this.httpSerive.post(url, body);
  }

  ChangePassword(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/ChangePassword`;
    return this.httpSerive.post(url, body);
  }

  ChangeMyPassword(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/ChangeMyPassword`;
    return this.httpSerive.post(url, body);
  }

  ChangeNewUserPassword(
    body
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `${this.apiController}/ChangeNewUsersPassword`;
    return this.httpSerive.post(url, body);
  }

  GetFacts(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/GetFacts`;
    return this.httpSerive.post(url, body);
  }

  AddFacts(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/AddFacts`;
    return this.httpSerive.post(url, body);
  }

  UpdateFacts(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/UpdateFacts`;
    return this.httpSerive.post(url, body);
  }

  DeleteFacts(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/DeleteFacts`;
    return this.httpSerive.post(url, body);
  }

  UploadFile(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminUpload/UploadFile`;
    console.log(body);
    return this.httpSerive.MultipartFormRequest(url, body);
  }

  GetNotification(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/GetNotificationsUser`;
    return this.httpSerive.post(url, body);
  }

  AddNotification(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/AddNotification`;
    return this.httpSerive.post(url, body);
  }

  DeleteNotification(
    body
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/DeleteNotifications`;
    return this.httpSerive.post(url, body);
  }

  GetStates(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/GetStates`;
    return this.httpSerive.post(url, body);
  }

  AddStates(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/Common/AddStateByserviceType`;
    return this.httpSerive.post(url, body);
  }

  DeleteStates(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/Common/DeleteStateservicetype`;
    return this.httpSerive.post(url, body);
  }

  GetStateByAppointmentType(
    body
  ): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminDepartment/GetAppointmentTypeByState`;
    return this.httpSerive.post(url, body);
  }

  GetQuestions(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/GetQuestions`;
    return this.httpSerive.post(url, body);
  }

  AddQuestions(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/AddQuestion`;
    return this.httpSerive.post(url, body);
  }

  DeleteQuestions(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/DeleteQuestions`;
    return this.httpSerive.post(url, body);
  }

  UpdateQuestions(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/UpdateQuestion`;
    return this.httpSerive.post(url, body);
  }

  GetPatient(body):Observable<InstantUcApiModels.InstantUcApiResponse>{
      let url = `api/Patient/GetPatientName`;
      return this.httpSerive.post(url,body);
  }

  GetInputType(body): Observable<InstantUcApiModels.InstantUcApiResponse> {
    let url = `api/AdminCommon/GetInputType`;
    return this.httpSerive.post(url, body);
  }


}
