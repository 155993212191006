// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



//FOR STAGING
export const environment = {
  production: false,
  API_BASE_PATH: "https://api.pcrown.net/hq/", // this is the dev environemtn
  API_KEY: "EV-OCT-EVENT-BIZBRO-2020LLY",
  AppointmentURL: "https://pcrown.net/", // dev url
  InClinicTMURL: "https://pcrown.net/", // dev url
  InClinicServiceURL: "https://pcrown.net/", // dev url
  ScannedQrUrl: "https://pcrown.net/", // dev url
  twilioNo: "7315034358",
  socketUrl: 'https://socket.pcrown.net/',
  RedirectUrl: "https://admin.pcrown.net/home/reputation",
  // RedirectUrl: "http://localhost:4201/home/reputation",
  firebase: {
        apiKey: "AIzaSyBOZ-6XWL7nSc_t4KhcrWJAIDi1lgfiKuE",
        authDomain: "panther-307815.firebaseapp.com",
        projectId: "panther-307815",
        storageBucket: "panther-307815.appspot.com",
        messagingSenderId: "1095191796664",
        appId: "1:1095191796664:web:abef6f0808323465d4cd4d",
        measurementId: "G-QTCBZKBPWM"
      },

  // ServerKey: "AAAAHLVBjck:APA91bHbhQsjwDUaPDgH-CYOQBQQNgIYNP2Ey1hJ7uotnB8ig6h-mnNTX_4FCV4MJswVLNxHxYu-xjzBT4oP6w5jY8BUibcchGbMxr-o88Zee4ZMbqeKxrXUanYcE1JXBV3-g7atyjUw",
  ServerKey: "lj4Q-KvyY2hbNngkFD8qftB9Nv0MhjbOMOrpULR6wkY",
  widgetLoaderBaseUrl: 'https://widget.pcrown.net/Widget/widgetLoader.js', // DEV SERVER
  widgetVersion: '1.0.0',
  siteKey : '6LeRwk0gAAAAAP3rBC5O1KfqMdepTBlBh3rK8Ai7',
  googleMyBusinessRedirectUrl:"https://admin.pcrown.net/practice/connectwithgoogle",
  MarketingCampaign:"https://admin.pcrown.net/MarketingCampaign/",
  InventoryUrl:"https://admin.pcrown.net/Inventory/",
  pcWidgetBaseUrl:`https://widget.pcrown.net/widget/`,
  aucWidgetBaseUrl:`https://widget.pcrown.net/auc/`,
  marketingLink:`https://admin.pcrown.net/MarketingCampaign`,
  InventoryLink:`https://admin.pcrown.net/Inventory/`,
  ImageBasePath:`https://admin.pcrown.net`
};

// For production

// export const environment = {
//   production: true,
//   API_BASE_PATH: "https://api.practicecrown.com/hq/", // this is the production  environemtn
//   API_KEY: "EV-OCT-EVENT-BIZBRO-2020LLY",
//   AppointmentURL: "https://practicecrown.com/d/",
//   InClinicServiceURL: "https://practicecrown.com/d/",
//   InClinicTMURL: "https://practicecrown.com/d/",
//   twilioNo: "8888166289",
//   socketUrl: 'https://socket.practicecrown.com/',
//   metheadSocketUrl: 'https://socket.pcrown.dev/',
//   RedirectUrl: "https://admin.practicecrown.com/home/reputation",
//   ScannedQrUrl: "https://practicecrown.com/d/",
//   firebase: {
//     apiKey: "AIzaSyBOZ-6XWL7nSc_t4KhcrWJAIDi1lgfiKuE",
//     authDomain: "panther-307815.firebaseapp.com",
//     projectId: "panther-307815",
//     storageBucket: "panther-307815.appspot.com",
//     messagingSenderId: "1095191796664",
//     appId: "1:1095191796664:web:abef6f0808323465d4cd4d",
//     measurementId: "G-QTCBZKBPWM"
//   },
//   ServerKey: "AAAA_v6Em7g:APA91bHFa29yCH8vGmvAVBazD6X-FCf00i-EwTm-_BdOKhjjk1eWm2O547jjdLdvfCu5i_ETzbf0kV9o3Ud9vfufuM6g3oIQ_Elk808TYMX_qIQfq7sZR9Kosqa2yDsPNne6Hcm-gAtD",
//   widgetLoaderBaseUrl: 'http://practicecrown.com/Widget/widgetLoader.js',
//   widgetVersion: '3.0.0',
//   siteKey: '6LeRwk0gAAAAAP3rBC5O1KfqMdepTBlBh3rK8Ai7',
//   googleMyBusinessRedirectUrl: "https://admin.practicecrown.com/practice/connectwithgoogle",
//   MarketingCampaign: "https://admin.practicecrown.net/MarketingCampaign/",
//   InventoryUrl: "https://admin.practicecrown.net/Inventory/",
//   pcWidgetBaseUrl: `https://widget.practicecrown.net/widget/`,
//   aucWidgetBaseUrl: `https://widget.practicecrown.net/auc/`,
//   marketingLink:`https://admin.practicecrown.com/MarketingCampaign`,
//   InventoryLink:`https://admin.practicecrown.com/Inventory/`,
//   ImageBasePath:`https://admin.practicecrown.com`
// };

