import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';



 @Injectable({
     providedIn:'root'
 })


export class LoginAuthgardComponent implements CanActivate, CanActivateChild {

    
    constructor(public router: Router) { }


    canActivate() {

        if (localStorage.getItem("access_token")) {
            return true;
            // if(localStorage.getItem("In/s\tan/tUC%")){
            //     return true;
            // }else{
            //     this.router.navigateByUrl("/practice")
            // }
        }
        else {
            this.router.navigateByUrl('/Auth')
        }
    }

    canActivateChild() {
        if (!localStorage.getItem("access_token")) {
            // this.router.navigateByUrl("/Auth")
             return true;
        }
        else {
            if (localStorage.getItem("In/s\tan/tUC%")) {
                this.router.navigateByUrl("/home")
            } else {
                 this.router.navigateByUrl("/practice")
                // this.router.navigateByUrl("/Auth")
            }
            //this.router.navigateByUrl('/login')
        }


    }
    // CanDeactivate() {
    //     if (!localStorage.getItem("access_token")) {
    //         return true;
    //     }
    //     else {
    //         if (localStorage.getItem("In/s\tan/tUC%")) {
    //             this.router.navigateByUrl("/home")
    //         } else {
    //             this.router.navigateByUrl("/practice")
    //         }
    //         this.router.navigateByUrl('/login')
    //     }


    }
    
















